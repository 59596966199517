import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import SameDayRegistrationComponent from './components/RegistrationComponent';
import LoginComponent from './components/LoginComponent';
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SameDayRegistrationComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/register-now" element={<SameDayRegistrationComponent />} />
        <Route path="*" element={<SameDayRegistrationComponent />} />
      </Routes>
    </div>
  );
}

export default App;
