import React, { useState, useEffect } from "react";
import "../assets/css/VirtualTerminalComponent.css";
import "../assets/css/responsive.css";
import Spinner from "../Spinner";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "./AdminNavbar";
import Footer from "./Footer";
import BlueSection from "./BlueSection";
import { ReactComponent as BackgroundSVG } from "../assets/img/background.svg";
import Login from "../Auth";

const LoginComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <BlueSection />
      <div className="donation-area bg-gray default-padding">
        {/* Fixed BG */}
        <Navbar />
        <div className={"fixed-bg"} id="bg-wrap">
          <BackgroundSVG />
        </div>
        {/* End Fixed BG */}
        <div className="container">
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 info text-light donation-form">
              <Login 
                updateIsLoading={setIsLoading} 
              />
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Spinner />}
      <ToastContainer />
      <Footer />
    </>
  );
};

export default LoginComponent;