import React from "react";
import Select from "react-select";
import {
  SEARCH_DROPDOWN_STYLE,
  DROPDOWN_STYLE,
  countryOptions,
  provinceOptions,
  stateOptions,
} from "./constants";
import "../assets/css/AttendeeForm.css";
import SingleChoiceDropdown from "./SingleChoiceDropdown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";


const theme = createTheme({
  palette: {
    customColor: {
      main: '#fcb61c'
    },
  },
});

class SingleAttendeeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: "",
        zipLabel: "Postal Code",
        selectedAttendee: null,
        isTransfer: false,
        selectedSponsor: null,
        paddleNumber: "",
        isTicketHolder: null,
      },
      errorEmail: "",
      errorPhoneNumber: "",
      pageTitle: "",
      sponsors: [
        { value: "RBC", label: "RBC" },
        { value: "Axiom", label: "Axiom" },
        { value: "MLA", label: "MLA" },
        { value: "Strand", label: "Strand" },
        { value: "Beedie", label: "Beedie" },
        { value: "Brown", label: "Brown" },
        { value: "Joey", label: "Joey" },
        { value: "Virtus", label: "Virtus" },
        { value: "Beck", label: "Beck" },
        { value: "Peterson", label: "Peterson" },
        { value: "Onni", label: "Onni" },
        { value: "CBRE", label: "CBRE" },
        { value: "Grosvenor", label: "Grosvenor" },
        { value: "Porte", label: "Porte" },
      ],
      expanded: 0,
      allAttendees: [],
      checkinAttendee: null,
      chairNum: "Help Desk 1",
      checkin_message: <p></p>,
      titleSuffix: <> [Staff Only]</>,
      errors: null
    };

    this.state.formData.selectedProvince = provinceOptions[0];
    this.state.formData.selectedCountry = countryOptions[0];
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.allCheckins();

    const isTest = window.location.hostname.includes("test.");

    if (isTest) {
      this.setState({ titleSuffix: <> [Staff Only, Test]</> });
    }

    const urlParams = new URLSearchParams(window.location.search);
    const chairNum = urlParams.get("chair_num");

    // Set the chairNum in the state if it exists
    if (chairNum) {
      this.setState({ chairNum });
    }
  }

  allCheckins = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/all-checkins`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        return false;
      }

      const responseBody = await response.json();

      this.setState({ allAttendees: responseBody });
    } catch (err) {
      return false;
    }
  };

  handleInputChange = (event) => {
    const { id, value } = event.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [id]: value,
      },
    }));
  };

  handleCountryChange = (selectedOption, formData, setFormData) => {
    let newLabel = "";
    let options = [];
    let newZipLabel = "";

    if (selectedOption.value === "USA") {
      newLabel = "State";
      options = stateOptions;
      newZipLabel = "Zip Code";
    } else if (selectedOption.value === "CAN") {
      newLabel = "Province";
      options = provinceOptions;
      newZipLabel = "Postal Code";
    }

    const updatedState = {
      selectedCountry: selectedOption,
    };

    if (newLabel !== formData.provinceLabel) {
      updatedState.provinceLabel = newLabel;
      updatedState.provinceOptions = options;
      updatedState.selectedProvince = options[0];
      updatedState.zipLabel = newZipLabel;
    }

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        ...updatedState,
      },
    }));
  };

  handleProvinceChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedProvince: selectedOption,
      },
    }));
  };

  toggleValue = (selectedOption, label) => {
    const value = selectedOption ? selectedOption.value : false;
    const formData = { ...this.state.formData };
    formData[label] = value;

    this.setState({ formData });
  };

  handleSubmit = async () => {
    this.props.updateIsLoading(true);
    const { formData, chairNum } = this.state;

    console.log("SCREAM");
    console.log(formData);

    // Check if any required field is empty
    const requiredFields = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      phoneNumber: "Phone Number",
      addressLine1: "Address Line 1",
      city: "City",
      zip: "Zip",
    };
    const emptyFields = Object.entries(requiredFields)
      .filter(([field, label]) => !formData[field])
      .map(([field, label]) => label);

    if (emptyFields.length > 0) {
      this.props.toast.error(
        `Please fill in all required fields: ${emptyFields.join(", ")}`
      );
      this.props.updateIsLoading(false);
      return;
    }

    try {
      const formDataCopy = { ...formData };

      formDataCopy.selectedCountry = formDataCopy.selectedCountry.label;
      formDataCopy.selectedProvince = formDataCopy.selectedProvince.label;
      formDataCopy["previous_attendee_id"] =
        formDataCopy.selectedAttendee?.value;
      formDataCopy["sponsor"] = formDataCopy.selectedSponsor?.label;
      formDataCopy["chairNumber"] = chairNum;

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/event-day-attendees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formDataCopy),
        }
      );

      if (response.ok) {
        this.setState({
          checkin_message: (
            <p>
              {formData.firstName} {formData.lastName} has been successfully
              checked in!
              <br />
              Paddle Number: {formData.paddleNumber}
              <br />
              Seat: See Nathania
            </p>
          ),
        });

        this.nextSection();
      } else {
        console.error("Failed to update attendee details");
      }
    } catch (error) {
      console.error("Error updating attendee details:", error);
    }

    this.props.updateIsLoading(false);
  };

  resetForm = async () => {
   await this.allCheckins();

    this.setState({
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        selectedProvince: provinceOptions[0],
        selectedCountry: countryOptions[0],
        provinceLabel: "Province",
        provinceOptions: provinceOptions,
        zip: "",
        zipLabel: "Postal Code",
        dietary: "",
        selectedAttendee: null,
        selectedSponsor: null,
        paddleNumber: "",
        isTransfer: false,
        isTicketHolder: null,
      },
      expanded: 0,
      checkinAttendee: null,
      errors: null,
      checkin_message: <p></p>
    }, () => console.log(this.state));
  };

  handleAttendeeChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedAttendee: selectedOption,
      },
    }));
  };

  handleCheckinAttendeeChange = (selectedOption) => {
    this.setState((prevState) => ({
      checkinAttendee: selectedOption,
    }));
  };

  handleSponsorChange = (selectedOption) => {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        selectedSponsor: selectedOption,
      },
    }));
  };

  getAttendeeOptions = () => {
    return this.props.attendees.map((attendee) => ({
      value: attendee.attendee_id,
      label: `${attendee.attendee_firstname} ${attendee.attendee_lastname}`,
    }));
  };

  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  nextSection = () => {
    this.setState((prevState) => ({
      expanded: prevState.expanded + 1,
    }));
  };

  prevSection = () => {
    this.setState((prevState) => ({
      expanded: prevState.expanded - 1,
    }));
  };

  getAttendeeOptions = () => {
    return this.state.allAttendees
      .filter(
        (att) => att.barcode && att.attendee_firstname && att.attendee_lastname
      )
      .map((att) => ({
        value: att.barcode,
        label: `${att.attendee_firstname} ${att.attendee_lastname}`,
      }));
  };

  refreshPage = async (e) => {
    e.preventDefault();
    this.props.updateIsLoading(true);

    await this.resetForm();

    window.scrollTo(0, 0);

    this.props.updateIsLoading(false);
  };

  getAttendeeByBarcode = (barcode) => {
    const { allAttendees } = this.state;
    const attendee = allAttendees.find((att) => att.barcode === barcode);

    console.log(attendee);
    return attendee || null;
  };

  checkin = async (e) => {
    e.preventDefault();
    this.props.updateIsLoading(true);
  
    const { checkinAttendee, chairNum } = this.state;

    if (!checkinAttendee) {
      this.props.updateIsLoading(false);
      const errors = {};
      errors.checkin = "No attendee has been selected.";


      this.setState({errors});

      return;
    }

    const identifier = checkinAttendee.value;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/checkin`, {
        method: "POST",
        body: JSON.stringify({
          barcode: identifier,
          chairNumber: chairNum,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        this.props.updateIsLoading(false);
        return false;
      }
  
      const responseBody = await response.json();
  
      if (responseBody.success) {
        const attendee = this.getAttendeeByBarcode(identifier);
  
        this.setState((prevState) => ({
          checkin_message: (
            <p>
              {attendee.attendee_firstname} {attendee.attendee_lastname} has
              been successfully checked in!
              <br />
              Paddle Number: {attendee.paddle_number}
              <br />
              Seat: TBA
            </p>
          ),
          formData: {
            ...prevState.formData,
            isTicketHolder: true,
          },
        }));

        this.nextSection();
      } else {
        // Handle the case where responseBody.success is false, if needed
        this.setState({
          checkin_message: (
            <p>
              Failed to check in the attendee. Please try again.
            </p>
          ),
        });
      }
  
      this.props.updateIsLoading(false);
    } catch (err) {
      this.setState({
        checkin_message: (
          <p>
            Attendee needs to be re-registered. Click on complete to reset the
            form.
          </p>
        ),
      });
  
      this.nextSection();
      this.props.updateIsLoading(false);
    }
  };
  

  get_checkin_attendee = (index) => {
    const { expanded, checkinAttendee, errors } = this.state;
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>Check In Information</h5>
        </AccordionSummary>
        <AccordionDetails>
        <div className="col-md-12">
            <div className="form-group">Ask Attendee: Did you complete registration?</div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="attendee">
                Select Attendee
              </label>
              <Select
                id="attendee"
                value={checkinAttendee}
                onChange={this.handleCheckinAttendeeChange}
                options={this.getAttendeeOptions()}
                styles={SEARCH_DROPDOWN_STYLE}
                placeholder="Select attendee"
                isSearchable
              />
            </div>
          </div>

{errors?.checkin && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.checkin}
                </div>
              )}

          <Button
          theme={theme}

            variant="contained"
            color="secondary"
            onClick={this.nextSection}
          >
            Needs to Finish Registration
          </Button>

          <Button variant="contained" color="primary" onClick={this.checkin}  className="danger">
            Submit
          </Button>


        </AccordionDetails>
      </Accordion>
    );
  };

  get_is_transfer = (index) => {
    const { formData, expanded, checkinAttendee, checkin_message } = this.state;

    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>Transfer Information</h5>
        </AccordionSummary>
        <AccordionDetails>
        <div className="col-md-12">
            <div className="form-group">Ask Attendee: Are you replacing someone?</div>
          </div>
          <SingleChoiceDropdown
            value={formData.isTransfer}
            toggleValue={(e) => this.toggleValue(e, "isTransfer")}
            label={"Is this a transfer?"}
            identifier="isTransfer"
            isRequired={true}
            outerClass="col-md-12"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.nextSection}
          >
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  get_sponsor_info = (index) => {
    const { formData, expanded, errors } = this.state;
  
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>Sponsor Ticket Information</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="sponsor">
                Sponsor <span className="required">*</span>
              </label>
              <Select
                id="sponsor"
                value={formData.selectedSponsor}
                onChange={this.handleSponsorChange}
                options={this.state.sponsors}
                styles={SEARCH_DROPDOWN_STYLE}
                placeholder="Select sponsor"
                isSearchable
              />
              {errors?.selectedSponsor && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.selectedSponsor}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="firstName">
                First Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={this.handleInputChange}
                required
              />
              {errors?.firstName && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.firstName}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="lastName">
                Last Name <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={this.handleInputChange}
                required
              />
              {errors?.lastName && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.lastName}
                </div>
              )}
            </div>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.validateAndProceed}
          >
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Validation and navigation function
  validateAndProceed = () => {
    const { formData } = this.state;
    const errors = {};
  
    // Validate fields
    if (!formData.selectedSponsor) {
      errors.selectedSponsor = 'Sponsor is required';
    }
    if (!formData.firstName) {
      errors.firstName = 'First name is required';
    }
    if (!formData.lastName) {
      errors.lastName = 'Last name is required';
    }
  
    // Update state with errors if any exist
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      // Proceed to the next section if no errors
      this.nextSection();
    }
  };  

  get_original_transfer_ticket_info = (index) => {
    const { formData, expanded, errors } = this.state;
  
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h5>Transfer Ticket Information</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="attendee">
                Original Ticket Holder <span className="required">*</span>
              </label>
              <Select
                id="attendee"
                value={formData.selectedAttendee}
                onChange={this.handleAttendeeChange}
                options={this.getAttendeeOptions()}
                styles={SEARCH_DROPDOWN_STYLE}
                placeholder="Select transferee"
                isSearchable
              />
              {errors?.selectedAttendee && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.selectedAttendee}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="firstName">
                Updated Ticket Holder First Name{" "}
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={this.handleInputChange}
                required
              />
              {errors?.firstName && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.firstName}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="lastName">
                Updated Ticket Holder Last Name{" "}
                <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={this.handleInputChange}
                required
              />
              {errors?.lastName && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.lastName}
                </div>
              )}
            </div>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.validateAndNextSection_transfer_ticket_info} // Update this line
          >
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  

  validateAndNextSection_transfer_ticket_info = () => {
    const { formData } = this.state;
    let errors = {};
  
    // Validation for required fields
    if (!formData.selectedAttendee) {
      errors.selectedAttendee = 'Original Ticket Holder is required';
    }
  
    if (!formData.firstName) {
      errors.firstName = 'Updated Ticket Holder First Name is required';
    }
  
    if (!formData.lastName) {
      errors.lastName = 'Updated Ticket Holder Last Name is required';
    }
  
    // Set errors if any
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.nextSection(); // Proceed to the next section if no errors
    }
  };
  

  get_finish = (index) => {
    const { formData, expanded, checkinAttendee, checkin_message } = this.state;

    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5>Summary</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">{checkin_message}</div>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={this.refreshPage}
            className="danger"
          >
            Complete
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };

  get_contact_info = (index) => {
    const { formData, expanded, errors } = this.state;
  
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <h5>Contact Information</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="email">
                Email <span className="required">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={this.handleInputChange}
                required
              />
              {errors?.email && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.email}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="phoneNumber">
                Phone Number <span className="required">*</span>
              </label>
              <input
                type="tel"
                className="form-control"
                id="phoneNumber"
                placeholder="Phone Number"
                value={formData.phoneNumber}
                onChange={this.handleInputChange}
                required
              />
              {errors?.phoneNumber && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.phoneNumber}
                </div>
              )}
            </div>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.validateAndNextSection_get_contact_info}
          >
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  
  // Function to validate contact info and proceed to the next section
  validateAndNextSection_get_contact_info = () => {
    const { formData } = this.state;
    let errors = {};
  
    if (!formData.email) {
      errors.email = 'Email is required';
    }
  
    if (!formData.phoneNumber) {
      errors.phoneNumber = 'Phone Number is required';
    }
  
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.nextSection();
    }
  };
  

  get_address_info = (index) => {
    const { formData, expanded, errors } = this.state;
  
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <h5>Address Information</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="addressLine1">
                Address Line 1 <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="addressLine1"
                placeholder="Address Line 1"
                value={formData.addressLine1}
                onChange={this.handleInputChange}
                required
              />
              {errors?.addressLine1 && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.addressLine1}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="addressLine2">Address Line 2</label>
              <input
                type="text"
                className="form-control"
                id="addressLine2"
                placeholder="Address Line 2"
                value={formData.addressLine2}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="city">
                City <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="city"
                placeholder="City"
                value={formData.city}
                onChange={this.handleInputChange}
                required
              />
              {errors?.city && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.city}
                </div>
              )}
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="selectedCountry">Country</label>
              <Select
                id="selectedCountry"
                value={formData.selectedCountry}
                onChange={(e) =>
                  this.handleCountryChange(
                    e,
                    formData,
                    this.handleProvinceChange
                  )
                }
                options={countryOptions}
                styles={SEARCH_DROPDOWN_STYLE}
              />
            </div>
          </div>

  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="selectedProvince">{formData.provinceLabel}</label>
              <Select
                id="selectedProvince"
                value={formData.selectedProvince}
                onChange={this.handleProvinceChange}
                options={formData.provinceOptions}
                styles={SEARCH_DROPDOWN_STYLE}
              />
            </div>
          </div>
  
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="zip">
                {formData.zipLabel} <span className="required">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="zip"
                placeholder={formData.zipLabel}
                value={formData.zip}
                onChange={this.handleInputChange}
                required
              />
              {errors?.zip && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.zip}
                </div>
              )}
            </div>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.validateAndNextSection_get_address_info} // Update this line
          >
            Next
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  

  validateAndNextSection_get_address_info = () => {
    const { formData } = this.state;
    let errors = {};
  
    // Validation for required fields
    if (!formData.addressLine1) {
      errors.addressLine1 = 'Address Line 1 is required';
    }
  
    if (!formData.city) {
      errors.city = 'City is required';
    }
  
    if (!formData.zip) {
      errors.zip = 'Zip code is required';
    }
  
    // Set errors if any
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.nextSection(); // Proceed to the next section if no errors
    }
  };
  
  get_paddle_info = (index) => {
    const { formData, expanded, errors } = this.state;
  
    return (
      <Accordion
        expanded={expanded === index}
        onChange={this.handleAccordionChange(index)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <h5>Paddle Information</h5>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="paddleNumber">Paddle Number <span className="required">*</span></label>
              <input
                type="text"
                className="form-control"
                id="paddleNumber"
                placeholder="Paddle Number"
                value={formData.paddleNumber}
                onChange={this.handleInputChange}
              />
              {errors?.paddleNumber && (
                <div style={{ color: 'red', marginTop: '5px' }}>
                  {errors.paddleNumber}
                </div>
              )}
            </div>
          </div>
  
          <Button
            variant="contained"
            color="primary"
            onClick={this.prevSection}
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="danger"
            onClick={this.validateAndSubmitPaddleInfo} // Update this line
          >
            Submit
          </Button>
        </AccordionDetails>
      </Accordion>
    );
  };
  

  validateAndSubmitPaddleInfo = () => {
    const { formData } = this.state;
    let errors = {};
  
    // Validation for required fields
    // You can adjust the conditions based on your requirements
    if (!formData.paddleNumber) {
      errors.paddleNumber = 'Paddle Number is required';
    }
  
    // Set errors if any
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
    } else {
      this.setState({ errors: {} });
      this.handleSubmit(); // Proceed to submit if no errors
    }
  };
  

  render() {
    const { formData, chairNum } = this.state;

    return (
      <div className="registration">
        <div className="col-md-12">
          <h4 style={{ color: "#2dccd3" }}>
            Event Day {chairNum}{this.state.titleSuffix}
          </h4>
        </div>

        {this.get_checkin_attendee(0)}

        {formData.isTicketHolder ? <>
            {this.get_finish(1)}
        </>: <>
        
        {this.get_is_transfer(1)}
        {formData.isTransfer ? (
          <>
            {this.get_original_transfer_ticket_info(2)}
            {this.get_contact_info(3)}
            {this.get_address_info(4)}
            {this.get_paddle_info(5)}
            {this.get_finish(6)}
          </>
        ) : (
          <>
            {this.get_sponsor_info(2)}
            {this.get_contact_info(3)}
            {this.get_address_info(4)}
            {this.get_paddle_info(5)}
            {this.get_finish(6)}
          </>
        )}

        </>}


      </div>
    );
  }
}

export default SingleAttendeeForm;